@import url('https://fonts.googleapis.com/css?family=Questrial|Shadows+Into+Light');

$primary: #c44e9d; /* MAIN COLOR */
$secondary: #169b9a; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa;
$border-radius: 0px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;
body{
	font-family: 'Questrial', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
	font-family: 'Questrial', sans-serif;

}
.top-pad{
	margin-top: 120px;
	@media(max-width:767px){
		margin-top: 85px;
	}
}
.flash {
	display:none;
}

nav {
	z-index: 1000;
}
.navbar {
	margin-bottom: 0;
}
.navbar .navbar-nav {
    > li > a {
		text-align: center;
		height: 100px;
		display: flex;
		align-items: center;


		@media (max-width: 767px) {
		    height: 22px;
		    display: inline-block;
		}

	    &:hover {
	    	background: $primary;
	    	color: lighten($primary, 50%);
	    }

		&:focus {
		  background: transparent;
		  color: #777;
		  outline: 0;
		}
	}
}

.navbar-right {
	margin-top: 0px;
}

.navbar-toggle {
    margin: 20px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;


}
a {
	color: $secondary;
	&:hover{
		color: $primary;
	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	max-width: 300px;
	text-align: center;
	margin: 6em auto;

	.close {display: none;}

	.modal-content {
		color: $primary;

		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}

	button {
		background: transparent;
		color: $wht;
		display: block;
		border:1px solid white;
		padding: 1em 2em;
		margin: 0 auto;

		&:hover {
			background: transparent;
			color: #fff;
			text-shadow: 0px 0px 3px $blk;
			border:none;
		}
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}
.logo {
	@media(max-width:767px){
		max-height: 70px;
	}
}
.hero-img {
	background-image: url('../img/hero-img.jpg');
	padding: 250px 0;
	margin-top: 100px;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	// background-blend-mode: color-dodge;
	box-shadow: inset 0 0 3em black, 5em 5em 1em red;
	h1{
		max-width: 600px;
		font-size: 3em;
		margin: 0 auto;
		color:white;
		font-weight: bold;text-shadow: 0 0 1em $primary;
		@media(max-width: 767px){
			font-size: 1.5em;
			font-weight: bold;text-shadow: 0 0 .3em $primary;
		}
	}
	@media(max-width: 767px){
		padding: 150px 0;
		margin-top: 70px;
	}
}
input#username {
	margin-bottom: 20px;
}
.highlight-clean {
  color:#313437;
  background-color:#fff;
  padding:50px 0;
}

.highlight-clean p {
  color:#7d8285;
}

.highlight-clean h2 {
  font-weight:bold;
  margin-bottom:25px;
  line-height:1.5;
  padding-top:0;
  margin-top:0;
  color:inherit;
	font-size: 1.5em;
	@media(max-width:767px){
		font-size: 1em;
	}
}

.highlight-clean .intro {
  font-size:16px;
  max-width:500px;
  margin:0 auto 25px;
}

.highlight-clean .buttons {
  text-align:center;
}

.highlight-clean .buttons .btn {
  padding:16px 32px;
  margin:6px;
  border:none;
  background:none;
  box-shadow:none;
  text-shadow:none;
  opacity:0.9;
  text-transform:uppercase;
  font-weight:bold;
  font-size:13px;
  letter-spacing:0.4px;
  line-height:1;
  outline:none;
}

.highlight-clean .buttons .btn:hover {
  opacity:1;
}

.highlight-clean .buttons .btn:active {
  transform:translateY(1px);
}

.highlight-clean .buttons .btn-primary {
  background-color:$primary;
  color:#fff;
}

.highlight-clean .buttons .btn-default {
  background-color:darken($wht, 10%);
  color:inherit;
}
// --------------------------------------------------------------------
.features-blue {
  color:#fff;
  background:linear-gradient(135deg, $primary, $secondary);
  background-color:$primary;
  padding-bottom:30px;
}

@media (max-width:767px) {
  .features-blue {
    padding-bottom:10px;
  }
}

.features-blue p {
  color:rgba(255,255,255,0.6);
}

.features-blue h2 {
  font-weight:bold;
  margin-bottom:40px;
  padding-top:40px;
  color:inherit;
}

@media (max-width:767px) {
  .features-blue h2 {
    margin-bottom:25px;
    padding-top:25px;
    font-size:2em;
		@media(max-width:767px){
			font-size: 1em;
		}
  }
}

.features-blue .intro {
  font-size:16px;
  max-width:500px;
  margin:0 auto 60px;

}

@media (max-width:767px) {
  .features-blue .intro {
    margin-bottom:40px;
  }
}

.features-blue .item {
  min-height:100px;
  padding-left:80px;
  margin-bottom:40px;
}

@media (max-width:767px) {
  .features-blue .item {
    min-height:0;
  }
}

.features-blue .item .name {
  font-size:1.5em;
  font-weight:400;
  margin-top:0;
  margin-bottom:20px;
  color:inherit;
	@media(max-width:767px){
		font-size: 1em;
	}
}

.features-blue .item .description {
  font-size:15px;
  margin-bottom:0;
}

.features-blue .item .icon {
  font-size:40px;
  color:$secondary;
  float:left;
  margin-left:-65px;
}



/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}

input#username, input#password {
	width: 100%;
}

@media (max-width: 1024) {
	input#username, input#password {
  		font-size: 16px;
	}
}

select {
    -moz-appearance: none;
    text-indent: 0.01px;
    text-overflow: '';
}


a,a:hover,a:focus,a:active {
  outline: 0 !important;
}


@media(max-width: 767px) {
  .navbar-header a {
    float: left;
  }
}

.modal-open {
  overflow: auto;
  padding-right: 0px !important;
}
